<template>
	<div
		v-show="false"
	>
		<table ref="excel_table">
			<thead>
				<tr
					v-if="excel_data.header_top"
				>
					<th></th>
					<th
						v-for="(th, index) in excel_data.header_top"
						:key="'th_t_' + th.column + '_' + index"
						:colspan="th.col"
					>{{ th.name }}</th>
				</tr>
				<tr>
					<th>No</th>
					<th
						v-for="(th, index) in excel_data.header"
						:key="'th_' + th.column + '_' + index"
					>{{ th.name }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(tr, key) in excel_data.content"
					:key="'tr_' + key"
				>
					<td>{{ key + 1}}</td>
					<td
						v-for="(td, d_index) in excel_data.header"
						:key="'tr_' + key + '_td_' + d_index + '_' + td.column"
					>
						{{ tr[td.column] }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

	import * as Xlsx from 'xlsx'

	export default{
		name: 'Excel'
		,props: ['date', 'excel_data']
		,data: function(){
			return {

			}
		}
		, computed: {
			item_list: function(){
				let t = []
				let d = []
				this.excel_data.content.filter( (item) => {
					d = []
					for(let i = 0; i < this.excel_data.header.length; i++) {
						let header = this.excel_data.header[i]
						d[header.name] = item[header.column]
					}
					t.push(d)
				})

				return t
			}
		}
		,methods: {
			toExcel: function(){

				// 엑셀 워크시트로 json 내보내기, 배열만 가능

				const dataWS = Xlsx.utils.json_to_sheet(this.item_list)
				// 엑셀의 workbook(엑셀파일에 지정된 이름)을 만든다
				const wb = Xlsx.utils.book_new();
				// workbook에 워크시트 추가, 시트명은 'peopleData'
				Xlsx.utils.book_append_sheet(wb, dataWS, 'sheet1');
				// 엑셀 파일을 내보낸다. 엑셀 파일 저장명은 'people.Xlsx'
				Xlsx.writeFile(wb, this.excel_data.name + this.$date.getToday() + '.xlsx');

				this.$emit('close')
			}
		}

		,mounted() {
			this.toExcel()
		}
	}


</script>