<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"

			@change="getData"
			@click="getData"
			@toExcel="toExcel"
		>
			<span
				slot="add"
			>
				<button
					slot="add"
					class="btn-white   ml-10"
					@click="setMonth(false)"
				><v-icon>mdi mdi-chevron-left</v-icon></button>
				<input
					v-model="search.month"
					class="pa-5-10 box  ml-10 text-center cursor-pointer"
					placeholder="검색 시작일"
					readonly
				/>
				<button
					slot="add"
					class="btn-white   ml-10"
					@click="setMonth(true)"
				><v-icon>mdi mdi-chevron-right</v-icon></button>
			</span>
			<select
				v-if="false"
				v-model="search.admin_code"
				slot="add"
				class="pa-5-10 mr-10"
				@change="getData"
			>
				<option value="">소속 대리점</option>
				<option
					v-for="(agency, index) in agency_list"
					:key="'agency_' + index"
					:value="agency.account_id"
				>{{ agency.agency_name }}</option>
			</select>


			<select
				v-model="search.cardNum"
				slot="add"
				class="pa-5-10 mr-10 ml-10"
				@change="getData"
			>
				<option value="">기프티콘</option>
				<option
					v-for="(mobile, m_index) in items_list"
					:key="'mobile_' + m_index"
					class="btn btn-s mr-5 mb-5"
					@change="getData"
					:value="mobile.goods_id"
				>{{ mobile.goods_nm }}</option>
			</select>

			<select
				v-model="search.tranStatus"
				slot="add"
				class="pa-5-10 mr-10 ml-10"
				@change="getData"
			>
				<option value="">처리 상태</option>
				<option value="normal">정상</option>
				<option value="error">오류</option>
			</select>

			<select
				v-model="search.useStatus"
				slot="add"
				class="pa-5-10 mr-10 ml-10"
				@change="getData"
			>
				<option value="">사용 상태</option>
				<option value="normal">정상</option>
				<option value="cancel">취소</option>
				<option value="return">환급</option>
			</select>

			<select
				v-if="false"
				v-model="search.member_level"
				slot="add"
				class="pa-5-10 mr-10"
				@change="getData"
			>
				<option value="">회원 등급</option>
				<option
					v-for="(code, index) in codes.M001.items"
					:key="'code_' + index"
					:value="code.code_index"
				>{{ code.code_name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 pa-10 bg-white full-height overflow-y-auto"
		>
			<div
				v-if="item_list.length > 0"
			>
				<table class="table table-even">
					<colgroup>
						<col
							width="80px"
						/>
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								v-if="false"
								type="checkbox"
							/>
							No
						</th>
						<th v-if="false">소속 대리점</th>
						<th>발급 일시</th>
						<th>아이디</th>
						<th>수신자 이름</th>
						<th>수신 번호</th>
						<th>주문 번호</th>
						<th>TID</th>
						<th>기프티콘</th>
						<th>상품금액</th>
						<th>PIN</th>
						<th>비밀번호</th>
						<th>처리 상태</th>
						<th>처리 내용</th>
						<th>사용 상태</th>
						<th>상세정보</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in item_list"
						:key="item.uid"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td
						>
							<input
								v-if="false"
								type="checkbox"
							/>
							{{ item.uid }}
						</td>
						<td v-if="false">{{ item.agency_name }}</td>
						<td>{{ item.wDate }}</td>
						<td>{{ item.member_id }}</td>
						<td>{{ item.d_name }}</td>
						<td>{{ item.d_tell }}</td>
						<td>{{ item.order_number }}</td>
						<td>{{ item.tranSeq }}</td>
						<td>{{ item.goods_name }}</td>
						<td>{{ item.pdt_price | makeComma}}원</td>
						<td>{{ item.appNo }}</td>
						<td>{{ item.issCd }}</td>
						<td>{{ item.tranStatus }}</td>
						<td>{{ item.resultStatus }}</td>
						<td :class="{'color-red' : item.orgTranSeq }">{{ item.orgTranSeq_name }}</td>
						<td>
							<v-icon
								@click="getDetail(item)"
								class="color-icon"
							>mdi mdi-arrow-right-bold-box-outline</v-icon>
						</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@close="is_excel = false"
		></Excel>

		<PopupLayer
			v-if="is_on_detail"
			:width="'480px'"
		>
			<template
				v-slot:body
			>
				<div
					class="pa-20 flex-column bg-white"
				>
					<div class="justify-space-between">
						<h6>모바일 기프티콘 상세 정보</h6>
						<button
							@click="is_on_detail = false"
						><v-icon>mdi mdi-close-circle</v-icon></button>
					</div>
					<table class="table mt-10 td-left">
						<col width="120px" />
						<tbody>
						<tr>
							<th>발급 일시</th>
							<td>{{ item.wDate}}</td>
						</tr>
						<tr>
							<th>아이디 </th>
							<td>{{ item.member_id}}</td>
						</tr>
						<tr>
							<th>수신자 이름 </th>
							<td>{{ item.d_name}}</td>
						</tr>
						<tr>
							<th>수신 번호 </th>
							<td>{{ item.d_tell}}</td>
						</tr>
						<tr>
							<th>주문 번호</th>
							<td>{{ item.order_number}}</td>
						</tr>
						<tr>
							<th>TID</th>
							<td>{{ item.tranSeq}}</td>
						</tr>
						<tr>
							<th>기프티콘</th>
							<td>{{ item.goods_name }}</td>
						</tr>
						<tr>
							<th>상품금액</th>
							<td>{{ item.pdt_price | makeComma }}원</td>
						</tr>
						<tr>
							<th>PIN</th>
							<td>{{ item_mobile.pinNo}}</td>
						</tr>
						<tr>
							<th>비밀번호</th>
							<td>{{ item_mobile.pinPassCode}}</td>
						</tr>
						<tr>
							<th>처리 상태</th>
							<td>{{ item_mobile.StatusText}}</td>
						</tr>
						<tr>
							<th>처리 내용</th>
							<td>{{ item.resultStatus}}</td>
						</tr>
						<tr>
							<th>사용 상태</th>
							<td :class="{'color-red' : item.orgTranSeq}">{{ item.orgTranSeq_name}}</td>
						</tr>
						<tr
							v-if="item.orgTranSeq"
						>
							<th>취소 TID</th>
							<td>{{ item.orgTranSeq}}</td>
						</tr>
						<tr
							v-if="item.orgTranSeq"
						>
							<th>취소 일시</th>
							<td>{{ item.appTm}}</td>
						</tr>
						</tbody>
					</table>

					<div
						v-if="item.tranSeq"
						class="mt-10 text-right"
					>
						<button
							v-if="!item.orgTranSeq"
							class="btn btn-danger"
							@click="postCancel"
						>취소 요청</button>
					</div>
					<div
						v-else
						class="mt-10 text-right"
					>
						<button
							v-if="!item.pgMerchAdjChk"
							class="btn btn-danger mr-10"
							@click="postReturnAll"
						>일괄 환급</button>
						<button
							v-if="!item.pgMerchAdjChk"
							class="btn btn-warning "
							@click="postReturn"
						>포인트 환급</button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupLayer
			v-if="is_on_return"
			:width="'480px'"
		>
			<template
				slot="body"
			>

				<div
					class=" flex-column bg-white"
				>
					<div class="justify-space-between under-line pa-10">
						<h6>모바일 상품권 환급</h6>
						<button
							@click="onCancel"
						><v-icon>mdi mdi-close-circle</v-icon></button>
					</div>

					<div class="pa-10">
						환급 처리 중입니다.


						<div
							style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
							class="pa-20"
						>
							<v-icon class="spin" large >mdi-rotate-left</v-icon>
						</div>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Excel from "@/components/Excel";
import Search from "@/view/Layout/Search";
import Empty from "@/view/Layout/Empty";
import PopupLayer from "../Layout/PopupLayer";

export default {
	name: 'OrderMobile'
	, components: {PopupLayer, Empty, Search, Excel, Pagination}
	, props: ['Axios', 'user', 'codes']
	,data: function (){
		return {
			program: {
				name: '모바일 기프티콘 내역'
				,top: true
				,title: true
			}
			,search: this.$storage.init({
				search_type: ''
				, page: 1
				, list_cnt: 10
				, gift_div: ''
				, cardNum: ''
				, member_level: ''
				, sDate: this.$date.getMonthlyDate('', '-').start
				, eDate: this.$date.getToday('-')
				, useStatus: ''
				, tranStatus: ''
				, month: this.$date.getToday('-').slice(0, 7)
			})
			,search_option:{
				is_excel: true
				,is_item: false
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'member_id'}
					, { name: '수신자 이름', column: 'd_name'}
					, { name: '수신 번호', column: 'd_tell'}
					, { name: '주문 번호', column: 'order_number'}
					, { name: 'TID', column: 'tranSeq'}
				]
			}
			,items: []
			,item_new: {
				ATOKEN: this.TOKEN
			}
			,agency_list: [

			]
			,is_excel: false
			,excel_data: {
				name: '모바일 기프티콘 내역'
				,header: [
					{ key: 0, name: 'No', column: 'uid'}
					,{ key: 0, name: '발급 일시', column: 'wDate'}
					,{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '수신자 이름', column: 'd_name'}
					,{ key: 0, name: '수신 번호', column: 'd_tell'}
					,{ key: 0, name: '주문번호', column: 'order_number'}
					,{ key: 0, name: 'TID', column: 'tranSeq'}
					,{ key: 0, name: '기프티콘', column: 'goods_name'}
					,{ key: 0, name: '상품금액', column: 'pdt_price'}
					,{ key: 0, name: 'PIN', column: 'appNo'}
					,{ key: 0, name: '비밀번호', column: 'issCd'}
					,{ key: 0, name: '처리상태', column: 'tranStatus'}
					,{ key: 0, name: '처리내용', column: 'resultStatus'}
					,{ key: 0, name: '사용 상태', column: 'orgTranSeq_name'}
				]
				,content: null
			}
			,is_item : false
			, items_agency: []
			, item: {}
			, item_mobile: {}
			, is_on_detail: false
			, items_giftcon: []
			, is_on_return: false
			, item_month: -1
			, item_search_excel: {
				page: 1
				, list_cnt: 5000
				, sDate: this.$date.getMonthlyDate('', '-').start
				, eDate: this.$date.getToday('-')
			}
			, items_excel: []
		}
	}
	,computed: {
		item_list: function (){
			return this.items.filter((item) => {

				switch (item.member_status){
					case '1':
						item.member_status_name = '사용'
						break;
					default:
						item.member_status_name = '미사용'
						break;
				}

				if(item.tranStatus == '1000'){

					if(item.orgTranSeq){
						item.orgTranSeq_name = '취소'
					}else{
						item.orgTranSeq_name = '정상'
					}
				}else{
					if(item.pgMerchAdjChk){
						item.orgTranSeq_name = '환급'
					}else{
						item.orgTranSeq_name = '-'
					}
				}

				this.items_giftcon.filter( (goods) => {
					if(goods.goods_id == item.cardNum){
						item.goods_name = goods.goods_nm
					}
				})
				return item
			})
		}
		, item_excel_list: function (){
			return this.items_excel.filter(function(item){

				switch (item.member_status){
					case '1':
						item.member_status_name = '사용'
						break;
					default:
						item.member_status_name = '미사용'
						break;
				}

				if(item.tranStatus == '0000'){

					if(item.orgTranSeq){
						item.orgTranSeq_name = '취소'
					}else{
						item.orgTranSeq_name = '정상'
					}
				}else{
					if(item.pgMerchAdjChk){
						item.orgTranSeq_name = '환급'
					}else{
						item.orgTranSeq_name = '-'
					}
				}
				return item
			})
		}
		,is_agency: function(){
			if(this.user.admin_type == 'agency'){
				return true
			}else{
				return false
			}
		}
		,select_option_agency: function(){
			let list = []
			this.agency_list.filter(function(item){

				list.push({
					name: item.agency_name
					,column: item.uid
				})
			})

			return list
		}
		, items_list: function(){
			let t = this.items_giftcon

			const result1 = t.sort((a, b) => a.goods_nm.toLowerCase() < b.goods_nm.toLowerCase() ? -1 : 1);

			return result1
		}
	}
	,methods: {
		getData: async function(){

			this.is_item = false
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getOrderGiftcon'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
					this.items_giftcon = result.data.list
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
				this.is_excel = false
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getAgencyList: async function() {
			if(this.user.role != this.codes.type_code_admin && this.user.route != this.codes.type_code_distributor){
				// return false
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						agency_type: 'A001003'
						, list_cnt: 100
					}
				})

				if(result.success){
					this.agency_list = result.data.result
					//this.search_option.select[0].items = this.select_option_agency
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
		, getDetail: async function (item){
			if(!item.tranSeq){
				this.item = item
				this.is_on_detail = true
				return
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getOrderGiftconDetail'
					,data: {
						tid: item.tranSeq
						, orderid: item.order_number
					}
					, version: 'v2'
				})

				if(result.success){
					this.item_mobile = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.item = item
				this.is_on_detail = true
			}
		}
		,toExcel: function(){
			this.getExcelData()
		}
		, getExcelData: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getOrderGiftcon'
					,data: this.item_search_excel
				})

				if(result.success){
					if(result.data.cnt > 0){
						if(Number(this.item_search_excel.page) > 1){
							this.items_excel = this.items_excel.concat(result.data.result)
						}else{
							this.items_excel = result.data.result
						}
						console.log(this.item_search_excel.page, this.items_excel.length)
						this.item_search_excel.page++

						await this.getExcelData()
					}else{
						this.excel_data.content = this.item_excel_list
						this.item_search_excel.page = 1
						this.is_excel = true
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,do: async function(){
			await this.getAgencyList()

			await this.getData()
		}
		, postCancel: async function(){
			if(!confirm("해당 기프티콘을 사용 취소 요청하시겠습니까?")){
				return false
			}
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/postGiftconCancel'
					,data: {
						tid: this.item.tranSeq
						, orderid: this.item.order_number
					}
				})

				if(result.success){
					this.is_on_detail = false

					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postReturn: async function(){
			if(!confirm("해당 기프티콘을 환급처리 하시겠습니까?")){
				return false
			}
			this.is_on_detail = false
			this.is_on_return = true
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postMobileReturn'
					,data: {
						uid: this.item.uid
						, service_code: this.item.cardNum
						, orderid: this.item.order_number
					}
				})

				if(result.success){
					this.$set(this.item, 'pgMerchAdjChk', result.data.tid)
					this.$set(this.item, 'appTm', result.data.CANCELTIME)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
				this.is_on_return = false
			}
		}
		, postReturnAll: async function(){
			if(!confirm("해당 주문관련 기프티콘을 일괄 환급처리 하시겠습니까?")){
				return false
			}
			this.is_on_detail = false
			this.is_on_return = true
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postMobileReturnAll'
					,data: {
						uid: this.item.uid
						, service_code: this.item.cardNum
						, orderid: this.item.order_number
					}
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
				this.is_on_return = false
			}
		}
		, onCancel: function(){
			if(confirm("처리 완료 이전에 창을 닫으면 정상처리 되지 않을수 있습니다. 창을 닫으시겠습니까?")){
				this.is_on_return = false
			}
		}
		, setMonth: function(input){
			let t = ''
			if(input){
				t = this.$date.getNextMonth(this.search.month, 1, '-')
			}else{
				t = this.$date.getPrevMonth(this.search.month, -1, '-')
			}
			let d = t.fullDate.slice(0, 7)
			let to = this.$date.getToday('-').slice(0, 7)

			if(d <= to){
				this.search.month = d
				this.search.sDate = d + '-01'
				this.search.eDate = d + '-31'
				this.item_search_excel.sDate = d + '-01'
				this.item_search_excel.eDate = d + '-31'
				this.getSearch(1)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.do()
	}
	,watch: {
	}
}
</script>